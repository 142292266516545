import classNames from 'classnames';
import Icon from 'components/Icon';
import RichText from 'components/RichText';
import { throttle } from 'lib/utils/throttle';
import { useRef, useEffect, useState } from 'react';
import useExpand from 'lib/hooks/useExpand';

const Disclaimer1a = ({
    hideTrigger,
    description,
    title,
    type,
    theme,
    attr,
}) => {
    const [hidden, setHidden] = useState(false);
    const trigger = useRef(null);
    const content = useRef(null);
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            return entries[0].isIntersecting ||
                entries[0].boundingClientRect.top <= 0
                ? setHidden(true)
                : setHidden(false);
        });
        observer.observe(content.current);
        return () => {
            observer.disconnect();
        };
    }, []);
    const { expand } = useExpand();
    return (
        <>
            <div className={`disclaimer bg-${theme}`} {...attr}>
                {!hideTrigger && !expand && (
                    <a
                        href="#disclaimer"
                        className={classNames('disclaimer__trigger', {
                            'disclaimer__trigger--hidden': hidden,
                        })}
                        ref={trigger}
                    >
                        <div className="disclaimer__trigger-wrap">
                            <div className="disclaimer__link">{title}</div>
                            <Icon
                                className="disclaimer__arrow"
                                icon={'fa-solid fa-angle-down'}
                            />
                        </div>
                    </a>
                )}
                <div className="container disclaimer__container">
                    <div
                        className="disclaimer__content"
                        id="disclaimer"
                        ref={content}
                    >
                        <div className="disclaimer__head">
                            <h4 className="disclaimer__title">{title}</h4>
                        </div>
                        {description && (
                            <RichText
                                className="disclaimer__description"
                                data={description}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Disclaimer1a;
