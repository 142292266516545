import Icon from 'components/Icon';
import RichText from 'components/RichText';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { close } from './notificationSlice';

const Notification = ({ description }) => {
    const { isOpen } = useSelector((state) => state.notification);
    const dispatch = useDispatch();
    function handleClick() {
        dispatch(close());
    }
    return (
        isOpen && (
            <div className="notification">
                <div className="notification__container">
                    {description && (
                        <RichText
                            className="notification__content"
                            data={description}
                        />
                    )}
                    <button
                        className="notification__close"
                        onClick={handleClick}
                    >
                        <Icon icon="fa-solid fa-close" />
                    </button>
                </div>
            </div>
        )
    );
};

export default Notification;
